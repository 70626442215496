import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/layouts/text.tsx";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>
  {`The FullCalendar project is supported by profits from `}
  <Link to='/pricing' mdxType="Link">FullCalendar Premium</Link>
    </p>
    <p>{`However, if you'd like to contribute a monetary gift to the lead contributor,
please feel free to do so:`}</p>
    <a href='http://arshaw.com/donate/' target='_blank'>
  Visit Adam Shaw's gift page &raquo;
    </a>
    <p>{`Thank you!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      