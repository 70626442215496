import React from 'react'
import { BaseLayout } from './base'

type TextLayoutProps = {
  header?: boolean
  title?: string
  pageContext?: {
    frontmatter?: {
      title?: string
    }
  }
}

export const TextLayout: React.FC<TextLayoutProps> = ({
  header = true,
  title,
  pageContext,
  children,
}) => {
  const pageTitle = pageContext?.frontmatter?.title ?? title
  return (
    <BaseLayout title={pageTitle}>
      <section className='page-content'>
        <div className='page-content__container container'>
          {header && <h1 className='page-content__title'>{pageTitle}</h1>}

          <div className='text-content'>{children}</div>
        </div>
      </section>
    </BaseLayout>
  )
}

export default TextLayout
